<template>
  <header>
    <div v-if="isLoaded" class="mobile-fix">
      <ul class="user-control">
        <li v-if="true">
          <span
            class="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <i class="fas fa-user-graduate" v-if="user.role === 'Student'"></i><i class="fas fa-user-tie" v-if="user.role === 'Professional'"></i> -->
            {{ user && user.firstName }} {{ user && user.lastName }}
          </span>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <router-link to="/dashboard" class="dropdown-item">
              Dashboard
            </router-link>
            <router-link to="/user/profile-edit" class="dropdown-item">
              Edit profile
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="#" @click="signOut" class="dropdown-item"
              >Sign out</a
            >
          </div>
        </li>
        <li>
          <router-link to="/events/student-qr" v-if="userRole === 'student'"
            ><img src="~@/assets/qr-code.png" class="qr_code" alt="" srcset=""
          /></router-link>
        </li>
        <!-- <li v-else>
         
           <router-link to="/register" class="btn btn-primary text-white">
              Register
            </router-link>
         
        </li>  -->
      </ul>
    </div>
  </header>
</template>

<script>
import router from '../router';
import { base64ToString } from '../utils/utils';

// import { mapState } from 'vuex';


let myRole = localStorage.getItem('i_am')
let userId = base64ToString(localStorage.getItem('uid'))

myRole = base64ToString(myRole)
export default {
  name: 'MasterHeader',
  props: {
    title: String,
  },
  data() {
    return {};
  },
  created(){
    if (myRole === 'student' && !this.$store.getters.studentData) {
      this.$store.dispatch('getStudentData', userId);
    } else if (myRole === 'professional' && !this.$store.getters.professionalData) {
      this.$store.dispatch('getProfessionalData', userId);
    }
  },
  computed: {
    userRole(){
      return myRole;
    },
    user(){
      if (this.userRole === 'student') return this.$store.getters.studentData
      if(this.userRole === 'professional') return this.$store.getters.professionalData
      return true
    },

    isLoaded: function () {
      var vm = this;
      var result = true;
      // console.log(vm.user.userId)

      // if (!vm.user.verified && vm.user.verified !== null) {
        // console.log("not verified");
        //vm.$router.push("/user/verify");
      // } else if (vm.user.status === 'new' && vm.user.role === 'Professional') {
      //   vm.$router.push('/user/welcome');
      // }
      return result;
    },
  },
  methods: {
    signOut() {
      const vm = this
      this.$store.dispatch('clearUser').then(() => {
        this.$store.dispatch('clearCollaborations').then(() =>{
        localStorage.clear()
        // window.location.reload()
        vm.$router.push('/signin').catch(() => {
          vm.$router.push('/signin').catch(() => {})
          // double edged sword, will get in touch with Vk if this keeps up
        })
      }) 
      })
    },
  },
};
</script>
